<template>
  <div :class="classes" @click="$emit('click')">
    <div :class="$style.cover">
      <img
        :class="$style.img"
        :src="getImage"
        @error="handleBrokeImage"
        alt=""
      />
      <div :class="$style.counter" v-if="count > 1">
        <span :class="$style.text">{{ count }}</span>
      </div>
    </div>
    <div :class="$style.name" v-if="isLarge || title">
      <div :class="$style.text">{{ achieve.name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AchievementPreview',
  props: {
    achieve: {
      type: Object,
      default: () => ({
        id: 1,
        name: 'Achievements',
        description: '',
        image_url: {
          small: '',
          large: ''
        },
        status: []
      })
    },
    title: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'large',
      validate: v => ['small', 'medium', 'large'].includes(v)
    },
    showAssignedStatus: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click'],
  data() {
    return {
      imageBroken: false
    }
  },
  methods: {
    handleBrokeImage() {
      this.imageBroken = true
    }
  },
  computed: {
    isLarge() {
      return this.size === 'large'
    },
    getImage() {
      return this.isLarge
        ? this.achieve?.image_url?.large
        : this.achieve?.image_url?.small
    },
    status() {
      return this.achieve.status || []
    },
    count() {
      return this.status.reduce((res, item) => {
        res += item.is_assigned ? 1 : 0
        return res
      }, 0)
    },
    assigned() {
      return this.status.some(s => s.is_assigned)
    },
    classes() {
      return [
        this.$style.container,
        this.$style[this.size],
        this.showAssignedStatus &&
          (this.assigned ? this.$style.assigned : this.$style.notAssigned)
      ]
    }
  }
}
</script>

<style module lang="scss">
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cover {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img {
  width: 100%;
  height: 100%;
}
.name {
  margin-top: 1.1em;
  text-align: center;
  flex: 1 1;
  .text {
    font-weight: 400;
    font-size: 1.2em;
    line-height: 1.333;
    color: #ffffff;
    font-style: normal;
  }
}
.counter {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2.3em;
  padding: 0.3em 0.4em;
  border-radius: 1.5em;
  background: #acb0d5;
  color: #36394c;
  .text {
    font-weight: 500;
    font-size: 1em;
    line-height: 100%;
  }
}
.notAssigned {
  filter: contrast(0.5) grayscale(1);
}

.large {
  .cover {
    width: 13.5em;
    height: 13.5em;
    @include down(sm) {
      width: 10.9em;
      height: 10.9em;
    }
  }
}
.medium {
  .cover {
    width: 8.9em;
    height: 8.9em;
  }
}
.small {
  .cover {
    width: 2.8em;
    height: 2.8em;
  }
}
</style>
